.enegmaTable :global(.ant-table-wrapper .ant-table) {
  font-family: var(--font-family);
  border-radius: 12px 12px 12px 12px;
}

.enegmaTable :global(.ant-table-wrapper table) {
  border: 1px solid rgba(228, 231, 236, 1);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.enegmaTable
  :global(.ant-table-wrapper .ant-table-thead .ant-table-cell:first-of-type) {
  border-start-start-radius: 12px;
}

.enegmaTable
  :global(.ant-table-wrapper .ant-table-thead .ant-table-cell:last-of-type) {
  border-start-end-radius: 12px;
}

.enegmaTable :global(.ant-table-wrapper .ant-table-thead th) {
  font-weight: 500;
  background-color: #fff;
  color: rgba(71, 84, 103, 1);
}

.enegmaTable :global(.ant-table-wrapper .ant-table-tbody tr > td) {
  color: rgba(16, 24, 40, 1);
  font-weight: 400;
  line-height: 20px;
}

.enegmaTable :global(.ant-table-wrapper .ant-table-tbody tr:last-of-type > td) {
  border-bottom: 0;
}

.enegmaTable
  :global(
    .ant-table-wrapper .ant-table-tbody tr:last-of-type > td:first-child
  ) {
  border-bottom-left-radius: 12px;
}

.enegmaTable
  :global(.ant-table-wrapper .ant-table-tbody tr:last-of-type > td:last-child) {
  border-bottom-right-radius: 12px;
}

.enegmaTable
  :global(.ant-table-wrapper .ant-table-tbody > .ant-table-row):nth-child(
    2n + 1
  ) {
  background-color: rgba(249, 250, 251, 1);
}

.enegmaTable :global(.ant-table-cell::before) {
  width: 0 !important;
}
