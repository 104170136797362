/* :global(.ant-btn) {
  border-radius: 4px;
  font-weight: 500;
  color: var(--textbase);
  border-color: #c0ccdb;
  font-family: var(--font-family);
}
:global(.ant-btn:disabled) {
  color: var(--text200);
}
:global(.ant-btn:hover:disabled) {
  color: var(--text200);
}

:global(.ant-btn.ant-btn-primary) {
  color: var(--white);
}

:global(.ant-btn.ant-btn-sm) {
  font-size: var(--font-size-13);
  padding: var(--space-4) var(--space-12);
  height: unset;
}

:global(.ant-btn:hover),
:global(.ant-btn:focus),
:global(.ant-btn:active) {
  color: var(--blue500);
  border-color: var(--blue500);
}
:global(.ant-btn.ant-btn-primary:hover),
:global(.ant-btn.ant-btn-primary:focus),
:global(.ant-btn.ant-btn-primary:active) {
  color: var(--white);
}

:global(.ant-btn.ant-btn-link) {
  border: none;
  color: var(--blue500);
}

:global(.ant-btn.ant-btn-link:disabled) {
  color: var(--text200);
} */

.dropdownBtn {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;

  padding: 4px 8px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.iconButton {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--space-4);
  align-items: center;

  padding: 4px 8px;
}

.buttonIcon svg {
  width: 12px;
  /* height: 12px; */
}

/* ALL ENEGMA BUTTONS */

.enegmaBtn > :global(.ant-btn) {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  border: 1px solid var(--brand600);
}

/* PRIMARY ENEGMA BUTTON */
.enegmaBtn :global(.ant-btn.ant-btn-primary) {
  background-color: var(--brand600);
}

.enegmaBtn :global(.ant-btn.ant-btn-primary) span {
  color: #fff;
}

.enegmaBtn
  > :global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover) {
  background-color: var(--brand700);
}

/* SECONDARY ENEGMA BUTTON */
.enegmaBtn :global(.ant-btn.ant-btn-default) {
  background-color: var(--brand200);
  border: 1px solid var(--brand200);
  border-radius: 10px;
}

.enegmaBtn :global(.ant-btn.ant-btn-default) span {
  color: var(--brand700);
}

.enegmaBtn
  > :global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover) {
  background-color: #fff;
  border: 1px solid var(--brand200);
}

.brandBtn {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;
}

.brandBtn > span {
  margin-inline-end: 0 !important;
}
