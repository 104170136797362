.header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    padding-bottom: var(--space-12);
    align-items: center;
}

.header .action {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: var(--space-8);
}

.createButton{
    display: grid !important;
    grid-auto-flow: column;
    grid-column-gap: var(--space-8);
    align-items: center;
    display: flex;
}

.createButton svg{
    height: 16px;
    width: 16px;
}

.createButton svg, .createButton svg path {
    fill: white !important;
    stroke: white !important;
}