:root {
  /* Primary Colors */
  /* Gray */
  --gray25: #fcfcfd;
  --gray50: #f9fafb;
  --gray100: #f2f4f7;
  --gray200: #e4e7ec;
  --gray300: #d0d5dd;
  --gray400: #98a2b3;
  --gray500: #667084;
  --gray600: #475467;
  --gray700: #344054;
  --gray800: #1d2939;
  --gray900: #101828;

  /* Brand */
  --brand25: #fcfaff;
  --brand50: #f9f5ff;
  --brand100: #f4ebff;
  --brand200: #e9d7fe;
  --brand300: #d6bbfb;
  --brand400: #b692f6;
  --brand500: #9e77ed;
  --brand600: #7f56d9;
  --brand700: #6941c6;
  --brand800: #53389e;
  --brand900: #42307d;

  /* Error */
  --error25: #fffbfa;
  --error50: #fef3f2;
  --error100: #fee4e2;
  --error200: #fecdca;
  --error300: #fda29b;
  --error400: #f97066;
  --error500: #f04438;
  --error600: #d92d20;
  --error700: #b42318;
  --error800: #912018;
  --error900: #7a271a;

  /* Warning */
  --warning25: #fffcf5;
  --warning50: #fffaeb;
  --warning100: #fef0c7;
  --warning200: #fedf89;
  --warning300: #fec84b;
  --warning400: #fdb022;
  --warning500: #f79009;
  --warning600: #dc6803;
  --warning700: #b54708;
  --warning800: #93370d;
  --warning900: #7a2e0e;

  /* Success */
  --success25: #f6fef9;
  --success50: #ecfdf3;
  --success100: #d1fadf;
  --success200: #a6f4c5;
  --success300: #6ce9a6;
  --success400: #32d583;
  --success500: #12b76a;
  --success600: #039855;
  --success700: #027a48;
  --success800: #05603a;
  --success900: #054f31;

  /* Secondary Colors */
  /* Blue Gray */
  --bluegray25: #fcfcfd;
  --bluegray50: #f8f9fc;
  --bluegray100: #eaecf5;
  --bluegray200: #d5d9eb;
  --bluegray300: #afb5d9;
  --bluegray400: #717bbc;
  --bluegray500: #4e5ba6;
  --bluegray600: #3e4784;
  --bluegray700: #363f72;
  --bluegray800: #293056;
  --bluegray900: #101323;

  /* Blue Light */
  --bluelight25: #f5fbff;
  --bluelight50: #f0f9ff;
  --bluelight100: #e0f2fe;
  --bluelight200: #b9e6fe;
  --bluelight300: #7cd4fd;
  --bluelight400: #36bffa;
  --bluelight500: #0ba5ec;
  --bluelight600: #0086c9;
  --bluelight700: #026aa2;
  --bluelight800: #065986;
  --bluelight900: #0b4a6f;

  /* Blue */
  --blue25: #f5faff;
  --blue50: #eff8ff;
  --blue100: #d1e9ff;
  --blue200: #b2ddff;
  --blue300: #84caff;
  --blue400: #53b1fd;
  --blue500: #2e90fa;
  --blue600: #1570ef;
  --blue700: #175cd3;
  --blue800: #1849a9;
  --blue900: #194185;

  /* Indigo */
  --indigo25: #f5f8ff;
  --indigo50: #eef4ff;
  --indigo100: #e0eaff;
  --indigo200: #c7d7fe;
  --indigo300: #a4bcfd;
  --indigo400: #8098f9;
  --indigo500: #6172f3;
  --indigo600: #444ce7;
  --indigo700: #3538cd;
  --indigo800: #2d31a6;
  --indigo900: #2d3282;

  /* Purple */
  --purple25: #fafaff;
  --purple50: #f4f3ff;
  --purple100: #ebe9fe;
  --purple200: #d9d6fe;
  --purple300: #bdb4fe;
  --purple400: #9b8afb;
  --purple500: #7a5af8;
  --purple600: #6938ef;
  --purple700: #5925dc;
  --purple800: #4a1fb8;
  --purple900: #3e1c96;

  /* Pink */
  --pink25: #fef6fb;
  --pink50: #fdf2fa;
  --pink100: #fce7f6;
  --pink200: #fcceee;
  --pink300: #faa7e0;
  --pink400: #f670c7;
  --pink500: #ee46bc;
  --pink600: #dd2590;
  --pink700: #c11574;
  --pink800: #9e165f;
  --pink900: #851651;

  /* Rose */
  --rose25: #fff5f6;
  --rose50: #fff1f3;
  --rose100: #ffe4e8;
  --rose200: #fecdd6;
  --rose300: #fea3b4;
  --rose400: #fd6f8e;
  --rose500: #f63d68;
  --rose600: #e31b54;
  --rose700: #c01048;
  --rose800: #a11043;
  --rose900: #89123e;

  /* Orange */
  --orange25: #fffaf5;
  --orange50: #fff6ed;
  --orange100: #ffead5;
  --orange200: #fddcab;
  --orange300: #feb273;
  --orange400: #fd853a;
  --orange500: #fb6514;
  --orange600: #ec4a0a;
  --orange700: #c4320a;
  --orange800: #9c2a10;
  --orange900: #7e2410;

  /* bg */
  --bg: #ffffff;
  --white: #ffffff;
  --bg400: #c2cbdc;
  --bg300: #dfe4ed;
  --bg200: #ecf1f8;
  --bg100: #f5f8fb;

  /* bg gray */
  --gray: #081c3d;
  --gray1: #081c3d;

  /* text color */
  --textbase: #282d38;
  --text500: #2d3e5a;
  --text400: #6b778c;
  --text300: #9ca4b1;
  --text200: #c1c6ce;
  --text100: #e1e3e7;
  --text-link: #485fd6;
  --text-inverse: #fffffa;

  /* icon color */
  --icon: #081c3d;
  --icon-dark: #394964;
  --icon-medium: #6b778b;
  --icon-light: #ced2d8;
  --icon-inverse: #fffffe;

  /* box shadow */
  --shadow-2dp: 0 1px 4px 0 rgba(194, 203, 220, 0.6);
  --shadow-4dp: 0 2px 5px 0 rgba(194, 203, 220, 0.6);
  --shadow-8dp: 0 2px 5px 0 #c2cbdc;
  --shadow-16dp: 0 5px 12px 0 rgba(194, 203, 220, 0.7);
  --shadow-top: 0 -1px 4px 0 rgba(194, 203, 220, 0.6);
  --shadow-slide: -4px 2px 8px 0 rgba(107, 119, 140, 0.3);
  --shadow-overlay: 0 4px 20px 0 #6b778c;

  /* spacing */
  --space-1: 0.1rem;
  --space-2: 0.2rem;
  --space-4: 0.4rem;
  --space-6: 0.6rem;
  --space-8: 0.8rem;
  --space-10: 1rem;
  --space-12: 1.2rem;
  --space-14: 1.4rem;
  --space-16: 1.6rem;
  --space-20: 2rem;
  --space-24: 2.4rem;
  --space-28: 2.8rem;
  --space-32: 3.2rem;
  --space-36: 3.6rem;
  --space-40: 4rem;
  --space-48: 4.8rem;
  --space-64: 6.4rem;

  /* font size */
  --font-size-10: 1rem;
  --font-size-11: 1.1rem;
  --font-size-12: 1.2rem;
  --font-size-13: 1.3rem;
  --font-size-14: 1.4rem;
  --font-size-16: 1.6rem;
  --font-size-18: 1.8rem;
  --font-size-20: 2rem;
  --font-size-24: 2.4rem;
  --font-size-28: 2.8rem;
  --font-size-36: 3.6rem;

  /* font weight */
  --light: 400;
  --medium: 500;
  --bold: 600;

  /* font family */
  --font-family: "Inter", sans-serif;

  /* ant select contaier */
  --select-height: 36px;

  /* kanban theme */

  --bg-color: rgb(0, 121, 191);
  --list-bg: #ebecf0;
  --card-bg: rgb(0, 121, 191);
  --bg-color: rgb(0, 121, 191);
}
