.customSearch:global(.ant-input-affix-wrapper) {
  padding: 10px 14px;
  border-radius: 8px;

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  column-gap: 8px;
  line-height: unset;
}

.customSearch:global(.ant-input-affix-wrapper input) {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: #a2a2a2;
  line-height: 20px;
}
