.flowCard {
  display: grid;
  height: 100px;
  width: 200px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fafafa;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(194, 203, 220, 0.6);
  padding: var(--space-12) var(--space-16);
  transition: 0.3s;
}

.flowCard:hover {
  box-shadow: 0 2px 5px 0 #c2cbdc;
}
