.pillTabs :global(.ant-tabs-nav::before) {
  /* Override the antd bottom border CSS style here */
  display: none;
}

.pillTabs :global(.ant-tabs-nav) {
  margin: 0;
}

.pillTabs :global(.ant-tabs-tab) {
  padding: 0;
}

.pillTabs :global(.ant-tabs-tab + .ant-tabs-tab) {
  margin: 0;
}

.pillTabs :global(.ant-tabs-nav-list) {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--space-16);
}

.pillTabs :global(.ant-tabs-tab-btn) {
  /* Override the antd tab button CSS style here */
  border-radius: 30px;
  padding: 3px 10px;
}

.pillTabs :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  /* Override the antd active tab button CSS style here */
  background: #000;
  color: #fff;
}

.pillTabs :global(.ant-tabs-ink-bar) {
  /* Override the antd bottom border CSS style here */
  display: none;
}
