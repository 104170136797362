.display2xl400 {
  font-size: 72px;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 90px;
  color: var(--textbase);
}

.display2xl500 {
  font-size: 72px;
  font-weight: 500;
  letter-spacing: -2%;
  line-height: 90px;
  color: var(--textbase);
}

.display2xl600 {
  font-size: 72px;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 90px;
  color: var(--textbase);
}

.display2xl700 {
  font-size: 72px;
  font-weight: 700;
  letter-spacing: -2%;
  line-height: 90px;
  color: var(--textbase);
}

.displayxl400 {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 72px;
  color: var(--textbase);
}

.displayxl500 {
  font-size: 60px;
  font-weight: 500;
  letter-spacing: -2%;
  line-height: 72px;
  color: var(--textbase);
}

.displayxl600 {
  font-size: 60px;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 72px;
  color: var(--textbase);
}

.displayxl700 {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -2%;
  line-height: 72px;
  color: var(--textbase);
}

.displaylg400 {
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 60px;
  color: var(--textbase);
}

.displaylg500 {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -2%;
  line-height: 60px;
  color: var(--textbase);
}

.displaylg600 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 60px;
  color: var(--textbase);
}

.displaylg700 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -2%;
  line-height: 60px;
  color: var(--textbase);
}

.displaymd400 {
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 44px;
  color: var(--textbase);
}

.displaymd500 {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -2%;
  line-height: 44px;
  color: var(--textbase);
}

.displaymd600 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 44px;
  color: var(--textbase);
}

.displaymd700 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -2%;
  line-height: 44px;
  color: var(--textbase);
}

.displaysm400 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 38px;
  color: var(--textbase);
}

.displaysm500 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -2%;
  line-height: 38px;
  color: var(--textbase);
}

.displaysm600 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 38px;
  color: var(--textbase);
}

.displaysm700 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -2%;
  line-height: 38px;
  color: var(--textbase);
}

.displayxs400 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 32px;
  color: var(--textbase);
}

.displayxs500 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -2%;
  line-height: 32px;
  color: var(--textbase);
}

.displayxs600 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 32px;
  color: var(--textbase);
}

.displayxs700 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -2%;
  line-height: 32px;
  color: var(--textbase);
}

.textxl400 {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: var(--textbase);
}

.textxl500 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--textbase);
}

.textxl600 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--textbase);
}

.textxl700 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--textbase);
}

.textlg400 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--textbase);
}

.textlg500 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--textbase);
}

.textlg600 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: var(--textbase);
}

.textlg700 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: var(--textbase);
}

.textmd400 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--textbase);
}

.textmd500 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--textbase);
}

.textmd600 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--textbase);
}

.textmd700 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--textbase);
}

.textsm400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--textbase);
}

.textsm500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--textbase);
}

.textsm600 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--textbase);
}

.textsm700 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--textbase);
}

.textxs400 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--textbase);
}

.textxs500 {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--textbase);
}

.textxs600 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: var(--textbase);
}

.textxs700 {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: var(--textbase);
}

.center {
  text-align: center;
}
