.sideMenu {
  height: 100%;
  width: 17rem;
  background: #fdfdfd;
  box-shadow: 0 1px 4px 0 rgba(194, 203, 220, 0.6);

  /* display: flex;
    flex-direction: column;
    align-items: center; */
  /* display: grid;
    grid-auto-rows: min-content;
    padding: 12px;
    grid-row-gap: 12px; */
}

.tabIcon {
  display: grid;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  /* max-width: 32px; */
  grid-gap: var(--space-8);
  font-family: var(--font-family) !important;
  font-weight: 500;
  cursor: pointer;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  padding: 2px 2px 2px 12px;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 22px;
}

/* .tabFirstIcon {
    padding-top: var(--space-12);
} */

.tabIcon .icon {
  /* padding: var(--space-8); */
  /* background: var(--bg200); */
  /* border-radius: 4px; */
  /* height: 3.6rem;
    width: 3.6rem; */
  /* display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center; */
  display: flex;
}

.tabIcon .icon svg {
  height: 2rem;
  width: 2rem;
}

.tabIcon.activeTab {
  background: #1677ff;
  color: #2c3140;
}

:global(.ant-menu-item-icon) svg {
  height: 1.6rem;
  width: 1.6rem;
}

:global(.ant-menu-dark .ant-menu-item-selected) {
  background: #eaeaea !important;
  color: #2c3140;
  font-weight: bolder;
}

:global(.ant-menu-dark .ant-menu-item) {
  color: #2c3140;
}
:global(.ant-menu-dark .ant-menu-item):hover {
  color: #2c3140 !important;
  background: #eaeaea !important;
}

:global(.ant-menu-dark .ant-menu-item-group-title) {
  color: #2c3140;
}

.siteSiderOverlay {
  /* display: grid; */
}
