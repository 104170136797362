.headerContent {
  display: grid;
  align-items: flex-end;
  text-align: left;
  grid-row-gap: 0.4rem;
}

.showPassword{
  align-self: start;
}

.signupForm :global(.ant-form-item) {
  margin-bottom: var(--space-16) !important;
  color: var(--textbase) !important;
}

.signupForm
  :global(.ant-form-item .ant-form-item-control-input-content input) {
  padding: var(--space-8) var(--space-12) !important;
}

.signupForm :global(.ant-form-item .ant-input-affix-wrapper) {
  padding: var(--space-8) var(--space-12) !important;
}

.signupForm :global(.ant-form-item .ant-input-affix-wrapper input) {
  padding: 0 !important;
}

.signupForm .passwordRow :global(.ant-form-item-control-input) {
  min-height: unset !important;
}

.signupForm .passwordRow:global(.ant-form-item) {
  margin-bottom: var(--space-20) !important;
}

.signupSccuess{
  display: grid;
  grid-row-gap: var(--space-8);
  justify-content: center;
}
.signupSccuess a{
  padding-top: var(--space-16);
}