.header {
  display: grid;
  grid-auto-flow: column;
  /* grid-template-columns: auto 500px auto; */

  align-items: center;
  justify-content: space-between;
  background-color: var(--bg);
  box-shadow: var(--shadow-2dp);
  padding: var(--space-8) 0;
  z-index: 1;
  width: 100%;
  /* background: #FAF9F6;
  background: #FFF5EE; */
  background: #fdfdfd;
  box-shadow: 0 1px 4px 0 rgba(194, 203, 220, 0.6);
}

.searchBox {
  width: 600px;
  padding: 1px 4px 1px 8px;
  display: grid;
  justify-self: center;
  align-items: center;
}

.searchBox :global(.input_searchInput__act5T) {
  display: flex;
}

.logo {
  padding: 0 var(--space-12);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  gap: 20px;
  font-size: 26px;
  font-weight: 700;
}

.logo svg {
  width: 11rem;
  height: 3rem;
}

.profileMenu {
  padding: 0 var(--space-20);
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--space-8);
  align-items: center;
  cursor: pointer;
}
.profileIcon img {
  height: var(--space-32);
  width: var(--space-32);
}
.profileImage {
  border-radius: 50%;
  object-fit: cover;
}
.dropdownIcon img {
  height: var(--space-12);
  width: var(--space-12);
}
