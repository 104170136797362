.customCheckbox :global(.ant-checkbox .ant-checkbox-inner) {
  border-radius: 1px !important;
  height: 18px;
  width: 18px;
  border: 1.5px solid #000;
}

.customCheckbox
  :global(.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #fff;
}

.customCheckbox
  :global(.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after) {
  border-width: 1px;
  border-color: #000;
}

.customCheckbox
  :global(
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
      .ant-checkbox-checked:not(.ant-checkbox-disabled)
      .ant-checkbox-inner
  ) {
  background-color: #fff;
}
