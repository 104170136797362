.icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.icon8 svg {
  height: 0.8rem;
  width: 0.8rem;
}
.icon10 svg {
  height: 1rem;
  width: 1rem;
}
.icon12 svg {
  height: 1.2rem;
  width: 1.2rem;
}
.icon16 svg {
  height: 1.6rem;
  width: 1.6rem;
}
.icon18 svg {
  height: 1.8rem;
  width: 1.8rem;
}
.icon20 svg {
  height: 2rem;
  width: 2rem;
}
.icon24 svg {
  height: 2.4rem;
  width: 2.4rem;
}
.icon32 svg {
  height: 3.2rem;
  width: 3.2rem;
}
.icon36 svg {
  height: 3.6rem;
  width: 3.6rem;
}
.icon40 svg {
  height: 4rem;
  width: 4rem;
}

.iconDark svg,
.iconDark svg g,
.iconDark svg path {
  fill: var(--icon-dark);
}

.iconMedium svg,
.iconMedium svg g,
.iconMedium svg path {
  fill: var(--icon-medium);
}

.iconLight svg,
.iconLight svg g,
.iconLight svg path {
  fill: var(--icon-light);
}

.iconInverse svg,
.iconInverse svg g,
.iconInverse svg path {
  fill: var(--icon-inverse);
}

.iconBlue500 svg,
.iconBlue500 svg g,
.iconBlue500 svg path {
  fill: var(--blue500);
}

.iconBrand500 svg,
.iconBrand500 svg g,
.iconBrand500 svg path {
  fill: var(--brand500);
  stroke: var(--brand500);
}

.disabled {
  cursor: not-allowed !important;
}

.disabled svg,
.disabled svg > * {
  fill: lightgray !important;
  /* stroke: lightgray; */
}
