.enegmaRadioGrp :global(.ant-radio-group) {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  color: rgba(52, 64, 84, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.enegmaRadioGrp :global(.ant-radio-group .ant-radio-button-wrapper) {
  border: 0;
  border-left: 1px solid rgba(208, 213, 221, 1);
  border-top: 1px solid rgba(208, 213, 221, 1);
  border-bottom: 1px solid rgba(208, 213, 221, 1);

  height: auto;
  padding: 10px 16px;
  line-height: 20px;
}

.enegmaRadioGrp :global(.ant-radio-group .ant-radio-button-wrapper::before) {
  width: 0;
}

.enegmaRadioGrp
  :global(
    .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked
  ) {
  background-color: rgba(249, 250, 251, 1);
  color: rgba(29, 41, 57, 1);
  font-weight: 600;
}

.enegmaRadioGrp
  :global(.ant-radio-group .ant-radio-button-wrapper:first-of-type) {
  border-start-start-radius: 8px;
  border-end-start-radius: 8px;
}

.enegmaRadioGrp
  :global(.ant-radio-group .ant-radio-button-wrapper:last-of-type) {
  border-right: 1px solid rgba(208, 213, 221, 1);
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
}
