.dropdownBtn:global(.ant-btn.ant-btn-default) {
  padding: 10px 16px !important;
  height: auto;
  border-radius: 8px;
  border-color: #e5e5e5;

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;

  line-height: 20px;
}

.dropdownBtn:global(.ant-btn.ant-btn-default span) {
  display: grid;
  grid-auto-flow: column;
  column-gap: 4px;
  align-items: center;
}

.dropdownBtn * {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  color: rgba(52, 64, 84, 1);
}
